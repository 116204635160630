import React from "react";
import { Button } from "@material-ui/core";
import LayoutCosmos from "../components/element/layout/LayoutCosmos";
import { logHttpError } from "../components/util/error-handling/sentry-integration";

const seo = {
  title: "Development Test Page - Oberion",
  description: null,
};
const heading = "Welcome to our Development Test Page";
const subheading = ["Try these buttons to test Teleskop:"];

const triggerSentry = async () => {
  await logHttpError(`${Date.now()}: payload test string`);
};

const buttons = [
  <Button
    onClick={triggerSentry}
    variant="outlined"
    color="secondary"
    fullWidth
  >
    Sentry
  </Button>,
];

/**
 * case: sentry
 * requirement: sentry enabled for develop environment in gatsby-config.js
 */
const DevTest = () => (
  <LayoutCosmos seo={seo} heading={heading} subheading={subheading} buttons={buttons} />
);
export default DevTest;
